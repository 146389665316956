// src/PhotoSection.js
import React from 'react';
import './PhotoSection.css';

const PhotoSection = () => {
  return (
    <div className="photo-section">
      {/* <img src="e_suitable.png" alt="Business 0" /> */}
      <img src="newphot2.webp" alt="Business 0" />
      <img src="num2.webp" alt="Business 1" />
      <img src="num3.webp" alt="Business 2" />
      <img src="num4.webp" alt="Business 3" />
    </div>
  );
};

export default PhotoSection;
