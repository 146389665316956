// CProductPage.js

import React, { useState } from 'react';
import './ProductPage.css';
import CloadImages from './CloadImages';

const CProductPage = () => {
  const [expandedPhoto, setExpandedPhoto] = useState(null);
  const photos = CloadImages();

  const handlePhotoClick = (index) => {
    setExpandedPhoto(index === expandedPhoto ? null : index);
  };

  return (
    <div className="product-page">
      <img src="availability2.webp" alt="availability" className="product-logo" />
      {photos.map((photo, index) => (
        <div
          key={index}
          className={`photo-item ${expandedPhoto === index ? 'expanded' : ''} ${photo.hot ? 'hot-product' : ''}`}
          onClick={() => handlePhotoClick(index)}
        >
          <img src={photo.src} alt={photo.name} />
          {photo.hot && <div className="hot-banner">Check it Out!</div>}
        </div>
      ))}
    </div>
  );
};

export default CProductPage;
