import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Main = () => {
  useEffect(() => {
    // Initialize Google Analytics
    window.gtag('config', 'G-Z8B8PZ7942', {
      page_path: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

root.render(<Main />);

reportWebVitals();
