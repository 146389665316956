// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/BusinessInfo.css */
.business-info {
    background-color: #fff;
    padding: 20px;
    text-align: center;
    border-top: 1px solid #ddd;
  }
  
  .business-info h2 {
    margin-top: 0;
    font-size: 1.8em;
    color: #333;
  }
  
  .business-info p {
    margin: 5px 0;
    font-size: 1.2em;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .business-info h2 {
      font-size: 1.5em;
    }
  
    .business-info p {
      font-size: 1em;
    }
  }
  
  @media (max-width: 480px) {
    .business-info h2 {
      font-size: 1.2em;
    }
  
    .business-info p {
      font-size: 0.9em;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/BusinessInfo.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,0BAA0B;EAC5B;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE;MACE,gBAAgB;IAClB;;IAEA;MACE,cAAc;IAChB;EACF;;EAEA;IACE;MACE,gBAAgB;IAClB;;IAEA;MACE,gBAAgB;IAClB;EACF","sourcesContent":["/* src/BusinessInfo.css */\n.business-info {\n    background-color: #fff;\n    padding: 20px;\n    text-align: center;\n    border-top: 1px solid #ddd;\n  }\n  \n  .business-info h2 {\n    margin-top: 0;\n    font-size: 1.8em;\n    color: #333;\n  }\n  \n  .business-info p {\n    margin: 5px 0;\n    font-size: 1.2em;\n    color: #555;\n  }\n  \n  @media (max-width: 768px) {\n    .business-info h2 {\n      font-size: 1.5em;\n    }\n  \n    .business-info p {\n      font-size: 1em;\n    }\n  }\n  \n  @media (max-width: 480px) {\n    .business-info h2 {\n      font-size: 1.2em;\n    }\n  \n    .business-info p {\n      font-size: 0.9em;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
