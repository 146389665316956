import React from 'react';
import './LinkPreviews.css'; // Assuming you have a CSS file for styling

const LinkPreviews = () => {
  const previewStyle = {
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '10px',
    marginBottom: '10px',
    textAlign: 'left',
  };

  // Array of eBay items with local images
  const ebayItems = [
    {
      id: 1,
      title: 'Flawless - #1 Babe Ruth',

      imageUrl: 'images/babe.webp',
      link: 'https://www.ebay.com/itm/375580008311?epid=7055863093&itmmeta=01J59H8BQYFC6H02GCSW7NJGMR&hash=item5772501f77:g:658AAOSwI55moUVz',
    },

    {
      id: 3,
      title: '2023 Mosaic CJ Stroud Rookie',

      imageUrl: 'images/stroud.webp',
      link: 'https://www.ebay.com/itm/375566042104?epid=5062934752&itmmeta=01J4A4QZGFDM4F6DTWHVPRVCHF&hash=item57717b03f8:g:VEIAAOSwI8Rmqjqz',
    },
    {
      id: 4,
      title: '2017 Panini Patrick Mahomes II',

      imageUrl: 'images/patrick.webp',
      link: 'https://www.ebay.com/itm/375572215726?epid=23055544025&itmmeta=01J4HWZ17W7GMTG6S5F6KZWQ4J&hash=item5771d937ae:g:qWIAAOSw28Rmrm58',
    },
    // {
    //   id: 5,
    //   title: 'Mikal Bridges 2018-19 Rookie',

    //   imageUrl: 'images/bridges.webp',
    //   link: 'https://www.ebay.com/itm/375566068562?epid=10056589282&itmmeta=01J4A4QZGFVGRE2Q13T6FN2Y7B&hash=item57717b6b52:g:JoMAAOSwwqNmqj4F',
    // },
    {
      id: 6,
      title: 'C.J. GRAHAM ~ AUTOGRAPHED',

      imageUrl: 'images/cjg.webp',
      link: 'https://www.ebay.com/itm/375582837883?itmmeta=01J59H8BQY5VZERMRM01W9KGZB&hash=item57727b4c7b:g:0NkAAOSwyBFmpqnP',
    },
    {
      id: 2,
      title: 'See All Our Live Auctions!',

      imageUrl: 'images/image0.webp',
      link: 'https://www.ebay.com/sch/i.html?item=375556738327&rt=nc&_trksid=p4429486.m3561.l161211&_ssn=twinpyros',
    },
    // {
    //   id: 3,
    //   title: '/image0.png'',

    //   imageUrl: '/images/auction3.jpg',
    //   link: 'https://www.ebay.com/itm/375556738328?itmmeta=01J3QXNAG28YGZ26DZQN302BPF&hash=item5770ed0d18:g:sqsAAOSwuatmnIco&itmprp=enc%3AAQAJAAABAMAYEChP%2Fx%2FtFI7qlsqJ0YUhkl914gBTyAIP3mfUt9oVzppYrn5wFdjQxlImfuMCpUUgOqs8FCsvGgvCmlnbOWvrwTssb9m1ppxnoZTVGoX3Ve%2BrJ8RsM82CfOWPu8ndnufzK0Yxpf8mCppS4o8dAslv9nx1fLp%2Bq830KqRb9Knfonsms058dYyGXL7raLFruYv8%2FF7iHpF9yI52gfDhHemXmeBNi7IcdkuKUrrTdRzuHfOoAquOC9y4kHPdit39tb9ZxEh5jZGeiqFKvybLLj0ic%2BvjZXDtM9v%2FfEl9VObvexR1lM8tll5VUA3K8BTFklgrCT1SPfBvlc6X8pm%2BqUU%3D|tkp%3ABFBMjqjV_Z1k',
    // },
    // {
    //   id: 4,
    //   title: 'Fireworks Bundle',
    //   
    //   imageUrl: '/images/auction2.jpg',
    //   link: 'https://www.ebay.com/itm/375556738328?itmmeta=01J3QXNAG28YGZ26DZQN302BPF&hash=item5770ed0d18:g:sqsAAOSwuatmnIco&itmprp=enc%3AAQAJAAABAMAYEChP%2Fx%2FtFI7qlsqJ0YUhkl914gBTyAIP3mfUt9oVzppYrn5wFdjQxlImfuMCpUUgOqs8FCsvGgvCmlnbOWvrwTssb9m1ppxnoZTVGoX3Ve%2BrJ8RsM82CfOWPu8ndnufzK0Yxpf8mCppS4o8dAslv9nx1fLp%2Bq830KqRb9Knfonsms058dYyGXL7raLFruYv8%2FF7iHpF9yI52gfDhHemXmeBNi7IcdkuKUrrTdRzuHfOoAquOC9y4kHPdit39tb9ZxEh5jZGeiqFKvybLLj0ic%2BvjZXDtM9v%2FfEl9VObvexR1lM8tll5VUA3K8BTFklgrCT1SPfBvlc6X8pm%2BqUU%3D|tkp%3ABFBMjqjV_Z1k',
    // },


    // Add more items as needed
  ];

  return (
    <div>
      
      <div style={previewStyle} className="header-container">
        <button className="facebook-button">
          <a
            href="https://www.facebook.com/people/Twin-Pyros-Fireworks-LLC/61556015133066/?mibextid=ZbWKwL"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f"></i>Facebook
          </a>
        </button>

      </div>
      <div className="ebay-items-container">
        {ebayItems.map((item) => (
          <div key={item.id} style={previewStyle} className="ebay-item">
            <img src={item.imageUrl} alt={item.title} />
            <div className="ebay-item-details">
              <h3>{item.title}</h3>
              <p>{item.price}</p>
              <button className="ebay-button">
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View eBay Item
                </a>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LinkPreviews;