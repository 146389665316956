// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/PhotoSection.css */
.photo-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: #f1f1f1;
    flex-wrap: wrap;
  }
  
  .photo-section img {
    width: 45%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 768px) {
    .photo-section img {
      width: 90%;
    }
  }
  
  @media (max-width: 480px) {
    .photo-section img {
      width: 100%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/PhotoSection.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,aAAa;IACb,yBAAyB;IACzB,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE;MACE,UAAU;IACZ;EACF;;EAEA;IACE;MACE,WAAW;IACb;EACF","sourcesContent":["/* src/PhotoSection.css */\n.photo-section {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 20px;\n    padding: 20px;\n    background-color: #f1f1f1;\n    flex-wrap: wrap;\n  }\n  \n  .photo-section img {\n    width: 45%;\n    height: auto;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n  }\n  \n  @media (max-width: 768px) {\n    .photo-section img {\n      width: 90%;\n    }\n  }\n  \n  @media (max-width: 480px) {\n    .photo-section img {\n      width: 100%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
