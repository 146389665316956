// // CloadImages.js
// At the top of your React component file
import imageData from './image_config.json';

// const loadImagesFromContext = (context) => {
//   return context.keys().map((key) => {
//     const name = key.replace('./', '').split('.')[0];
//     const src = context(key);
//     return { src, name };
//   });
// };

// const CloadImages = () => {
//   const regularContext = require.context('/public/Cimages', false, /\.(jpg|jpeg|png|gif)$/);
//   const hotContext = require.context('/public/HotCimages', false, /\.(jpg|jpeg|png|gif)$/);

//   const regularPhotos = loadImagesFromContext(regularContext);
//   const hotPhotos = loadImagesFromContext(hotContext).map(photo => ({ ...photo, hot: true }));

//   // Interspersing hot photos into regular photos
//   const interspersedPhotos = [];
//   const hotPhotosLength = hotPhotos.length;
//   const interval = Math.ceil(regularPhotos.length / (hotPhotosLength + 1));

//   let hotIndex = 0;
//   for (let i = 0; i < regularPhotos.length; i++) {
//     if (hotIndex < hotPhotosLength && i % interval === 0) {
//       interspersedPhotos.push(hotPhotos[hotIndex]);
//       hotIndex++;
//     }
//     interspersedPhotos.push(regularPhotos[i]);
//   }

//   return interspersedPhotos;
// };

// export default CloadImages;

const CloadImages = () => {
  // Assuming imageData structure contains keys like Cimages, HotCimages, etc.
  const regularPhotos = imageData.Cimages.map(url => ({ src: url, name: url.split('/').pop() }));
  const hotPhotos = imageData.HotCimages.map(url => ({ src: url, name: url.split('/').pop(), hot: true }));

  // Interspersing hot photos into regular photos
  const interspersedPhotos = [];
  const hotPhotosLength = hotPhotos.length;
  const interval = Math.ceil(regularPhotos.length / (hotPhotosLength + 1));

  let hotIndex = 0;
  for (let i = 0; i < regularPhotos.length; i++) {
    if (hotIndex < hotPhotosLength && i % interval === 0) {
      interspersedPhotos.push(hotPhotos[hotIndex]);
      hotIndex++;
    }
    interspersedPhotos.push(regularPhotos[i]);
  }

  return interspersedPhotos;
};

export default CloadImages;
