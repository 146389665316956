// const XloadImages = () => {
//     const context = require.context('/public/Ximages', false, /\.(jpg|jpeg|png|gif)$/);
//     return context.keys().map((key) => {
//       const name = key.replace('./', '').split('.')[0];
//       const src = context(key);
//       return { src, name };
//     });
//   };
  
//   export default XloadImages;
  
import imageData from './image_config.json';



const XloadImages = () => {
  // Assuming imageData structure contains keys like Cimages, HotCimages, etc.
  const regularPhotos = imageData.Ximages.map(url => ({ src: url, name: url.split('/').pop() }));
  const hotPhotos = imageData.HotXimages.map(url => ({ src: url, name: url.split('/').pop(), hot: true }));

  // Interspersing hot photos into regular photos
  const interspersedPhotos = [];
  const hotPhotosLength = hotPhotos.length;
  const interval = Math.ceil(regularPhotos.length / (hotPhotosLength + 1));

  let hotIndex = 0;
  for (let i = 0; i < regularPhotos.length; i++) {
    if (hotIndex < hotPhotosLength && i % interval === 0) {
      interspersedPhotos.push(hotPhotos[hotIndex]);
      hotIndex++;
    }
    interspersedPhotos.push(regularPhotos[i]);
  }

  return interspersedPhotos;
};

export default XloadImages; 
    