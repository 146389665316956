// Header.js
// import React from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
// import './Header.css'; // Ensure you create this CSS file for styling

// const Header = () => {
//   return (
//     <header className="header">
//       <div className="header-content">
//         <img src="image0.webp" alt="Left Logo" className="header-logo" />
//         <div className="header-text">
//           <h1>Twin Pyros</h1>
//           <h2>Collectibles, Novelties & Fireworks</h2>
            
//           <h3>
//             <Link to="/#business-info" className="header-link">
//                Open Now! Brazil, IN
//             </Link>
//           </h3>
//         </div>
//         <img src="image0.webp" alt="Right Logo" className="header-logo" />
//       </div>
//     </header>
//   );
// };

// export default Header;

// Header.js
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import './Header.css'; // Ensure you create this CSS file for styling

const Header = () => {
  return (
    <header className="header halloween-header">
      <div className="header-content">
        <img src="pumpkin.webp" alt="Left Pumpkin Logo" className="header-logo pumpkin-logo" />
        
        <div className="header-text">
          <h1 className="halloween-title">Twin Pyros</h1>
          <h2 className="halloween-subtitle">Collectibles, Novelties & Fireworks</h2>
            
          <h3>
            <Link to="/#business-info" className="header-link halloween-link">
               Open Now!
            </Link>
          </h3>
        </div>

        <img src="pumpkin.webp" alt="Right Pumpkin Logo" className="header-logo pumpkin-logo" />
      </div>
    </header>
  );
};

export default Header;
