// GoogleMapEmbed.js
import React, { useState, useEffect } from 'react';

const GoogleMapEmbed = () => {
  const [hasError, setHasError] = useState(false);
  const [isValidKey, setIsValidKey] = useState(true);

  // Access the API key securely from environment variables
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const address = encodeURIComponent("640 W. National Ave, Brazil, IN 47834");
  const googleMapsEmbedUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${address}`;

  useEffect(() => {
    const checkApiKey = async () => {
      try {
        const response = await fetch(googleMapsEmbedUrl);
        if (!response.ok) {
          setIsValidKey(false);
        }
      } catch (error) {
        setIsValidKey(false);
      }
    };

    checkApiKey();
  }, [googleMapsEmbedUrl]);

  const handleError = () => {
    setHasError(true);
  };

  return (
    <div>
      {(!isValidKey || hasError) ? (
        <div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
          Sorry, we are unable to load the map at this time. Please try again later.
        </div>
      ) : (
        <iframe
          title="Google Map"
          width="100%"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
          src={googleMapsEmbedUrl}
          onError={handleError}
        ></iframe>
      )}
    </div>
  );
};

export default GoogleMapEmbed;
