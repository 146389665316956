// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ProductPage.css */
.product-page {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  .product-logo {
    height: 40vmin;
    pointer-events: none;
  }
  .photo-item {
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .photo-item img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .photo-item p {
    text-align: center;
    margin-top: 10px;
  }
  
  .photo-item.expanded {
    grid-column: span 2;
    transform: scale(1.2);
    z-index: 1;
  }
  
  
  .photo-item.hot-product img {
    border: 2px solid red;
  }

  .hot-banner {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 3px;
    z-index: 1;
  }`, "",{"version":3,"sources":["webpack://./src/ProductPage.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,aAAa;IACb,4DAA4D;IAC5D,SAAS;IACT,aAAa;EACf;EACA;IACE,cAAc;IACd,oBAAoB;EACtB;EACA;IACE,kBAAkB;IAClB,eAAe;IACf,+BAA+B;EACjC;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,qBAAqB;IACrB,UAAU;EACZ;;;EAGA;IACE,qBAAqB;EACvB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,sCAAsC;IACtC,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;EACZ","sourcesContent":["/* ProductPage.css */\n.product-page {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\n    gap: 20px;\n    padding: 20px;\n  }\n  .product-logo {\n    height: 40vmin;\n    pointer-events: none;\n  }\n  .photo-item {\n    position: relative;\n    cursor: pointer;\n    transition: transform 0.3s ease;\n  }\n  \n  .photo-item img {\n    width: 100%;\n    height: auto;\n    display: block;\n  }\n  \n  .photo-item p {\n    text-align: center;\n    margin-top: 10px;\n  }\n  \n  .photo-item.expanded {\n    grid-column: span 2;\n    transform: scale(1.2);\n    z-index: 1;\n  }\n  \n  \n  .photo-item.hot-product img {\n    border: 2px solid red;\n  }\n\n  .hot-banner {\n    position: absolute;\n    top: 10px;\n    left: 10px;\n    background-color: rgba(255, 0, 0, 0.8);\n    color: white;\n    padding: 5px 10px;\n    font-size: 14px;\n    font-weight: bold;\n    border-radius: 3px;\n    z-index: 1;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
