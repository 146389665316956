// import logo from './logo.svg';
import React from 'react';
// import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import PhotoSection from './PhotoSection';
import BusinessInfo from './BusinessInfo';
import GoogleMapEmbed from './mapsgoog'; // Ensure the import path is correct
//  import LogoPage from './LogoPage'; // Import the LogoPage component
import FWProductsPage from './FWProductsPage';
import XProductsPage from './XProductsPage';
import CProductsPage from './CProductsPage';
import MProductsPage from './MProductsPage';
import HProductsPage from './HProductsPage';
import './App.css';
import Navbar from './navbar'; // Import the Navbar component
import LinkPreviews from './LinkPreviews'; // Import the new component

function App() {

  return (
  <Router>
    <div className="App">
    <Header />
    <Navbar /> {/* Use the Navbar component */}
      
        <Routes>
          <Route path="/" element={
            
            <div>
              <div id="links">
                <LinkPreviews /> {/* Add the LinkPreviews component here */}
              </div>
              <div id="photo-section">
                <PhotoSection />
              </div>
              <div id="business-info">
                <BusinessInfo />
              </div>
              <div id="map">
              <GoogleMapEmbed />
              </div>
              
            </div>
          } />
          
          <Route path="/FWproducts" element={<FWProductsPage />} />
          <Route path="/Cproducts" element={<CProductsPage />} /> 
          <Route path="/Xproducts" element={<XProductsPage />} />  
          <Route path="/Mproducts" element={<MProductsPage />} /> 
          <Route path="/Hproducts" element={<HProductsPage />} />    
          {/* <Route path="/logo" element={<LogoPage />} /> Add the new logo page route */}
        
        </Routes>
      </div>
    </Router>
  );
};


export default App;


