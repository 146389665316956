// src/BusinessInfo.js
import React from 'react';
import './BusinessInfo.css';

const BusinessInfo = () => {
  return (
    <div className="business-info">
      <h2>Business Hours</h2>
      <p>Open Daily</p>
      <p>Regular Business Hours </p>
      <h2>Visit Us:</h2>
      {/* add inline style to make ' Twin Pyros ' bold */}
      <p style={{ fontWeight: 'bold' }}>Twin Pyros</p>
      <p>640 W National Ave, Brazil, IN 47834</p>
      {/* <p>Saturday: 10:00 AM - 4:00 PM</p> */}
      {/* <p>Sunday: Closed</p> */}
      <h2>Contact Us:</h2>
      <p>Phone: (317) 999-5051</p>
      <p>Email: admin@adsolive.com</p>
    </div>
  );
};

export default BusinessInfo;
